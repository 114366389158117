import { useEffect, useState, useMemo, useLayoutEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Grid, IconButton, Box, CircularProgress } from "@mui/material";
import useStyles from "./styles";
import {
    Edit as EditIcon,
    Delete as DeleteIcon
} from "@mui/icons-material";
import { MaterialReactTable } from "material-react-table";

//components
import PageTitle from "../../components/Titles/PageTitle";
import { getFormattedDateTime } from "../../utils/GetFormattedDateTimeDay";
import GenerateEndpointChunks from "../../utils/GenerateEndpointChunks";
import IconsWithTooltip from "../../components/Icons/IconsWithTooltip";
import CustomToolbarWithExport from "../../components/CustomDataGridOptions/CustomToolbarWithExport";
import DetailsPanel from "../../components/Panels/DetailsPanel";
import { ConfirmationDialog } from "../../components/Dialog/ConfirmationDialog";

//custom hook
import { useFetch } from "../../hooks/useFetch";

//context
import { useManagerData } from "../../context/ManagerContext";
import { useAuth } from "../../context/AuthContext";

//constants
import { ManagerConstants, ApiEndpoints } from "../../utils/Constants";

//themes
import themes from "../../theme/defaultTheme";

const MapIncidentsData = (serviceCallsData) => {
    const mappedData = serviceCallsData?.map(incident => ({
        "id": incident.id,
        "truckNumber": incident.shiftInfo?.truckNumber || "",
        "phoneNumber": incident.shiftInfo?.phoneNumber || "",
        "primaryPatroller": incident.shiftInfo?.loggedInPatroller || "",
        "otherPatrollers": incident.shiftInfo?.otherPatrollers?.join(", ") || "",
        "route": incident.location?.route?.name || "",
        "freeway": incident.location?.freeway?.name || "",
        "crossStreet": incident.location?.crossStreet?.name || "",
        "callInTime": incident.callInTime ? getFormattedDateTime(new Date(incident.callInTime)) : "",
        "f1017": incident.f1017 ? getFormattedDateTime(new Date(incident.f1017)) : "",
        "startTime": incident.time.startTime ? getFormattedDateTime(new Date(incident.time.startTime)) : "",
        "endTime": incident.completedOn ? getFormattedDateTime(new Date(incident.completedOn)) : "",
        "lanesBlocked": incident.location.lanesBlocked ? incident.location.lanesBlocked.map(lane => lane.name).join(", ") : "",
        "detectMethod": incident.time?.detectMethod || "",
        "typeOfAssist": incident.vehicles?.length > 0 ? [...new Set(incident.vehicles.map(vehicle => vehicle.typeOfAssist?.name))].join(", ") : "",
        "serviceProvided": incident.vehicles?.length > 0
            ? [...new Set(incident.vehicles.flatMap(vehicle => vehicle.serviceProvided?.map(service => service.name) || []))].join(", ")
            : "",
        "lastModified": incident?.lastModifiedOn ? getFormattedDateTime(new Date(incident.lastModifiedOn)) : "",
        "lastModifiedOn": incident?.lastModifiedOn ? new Date(incident.lastModifiedOn) : null,
        "status": incident.status
    }))
    return mappedData;
}

export default function ViewServiceCalls() {
    const { StyledCard, StyledGrid, StyledCardContent, StyledChip } = useStyles();
    const { updateServiceCalls } = useManagerData();
    const [rows, setRows] = useState([]);
    const [primaryCols, setPrimaryCols] = useState([]);
    const [secondaryCols, setSecondaryCols] = useState([]);
    const [selectedDateRange, setSelectedDateRange] = useState({});
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState([]);
    const [mappingLoad, setMappingLoad] = useState(false);
    const navigate = useNavigate();
    const { logout } = useAuth();
    const getFetcher = useFetch("get");

    //call endpoints in list async
    const fetchEndpointsAsync = async (endpointsList) => {

        //helper function to wait for a specified time (ms)
        const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

        try {

            //calculating number of batches as per batch size 
            const totalBatches = Math.ceil(endpointsList.length / ManagerConstants.MAX_BATCH_SIZE);
            let allMappedServiceCalls = [];
            for (let i = 0; i < totalBatches; i++) {

                //get current batch of endpoints
                const batch = endpointsList.slice(i * ManagerConstants.MAX_BATCH_SIZE, (i + 1) * ManagerConstants.MAX_BATCH_SIZE);

                //fetch all chunks in parallel
                const responses = await Promise.all(
                    batch.map(url =>
                        getFetcher
                            .setRequest({ endpoint: url })
                            .then(data => {
                                return data;
                            })
                            .catch(err => {
                                console.error(`Error fetching ${url}:`, err);
                                return null;
                            })
                    )
                );

                // Filter out null responses and process data
                const validResponses = responses.filter(response => response !== null);
                if (validResponses?.length > 0) {
                    const mappedServiceCalls = validResponses.flatMap(response => {
                        if (response?.error) {

                            // Handle logout for unauthorized/forbidden responses
                            if (response.errorStatus === 403 || response.errorStatus === 401) {
                                logout();
                            }
                            return [];
                        }

                        // Map data if incidents exist
                        return response?.data?.incidents
                            ? MapIncidentsData(response.data.incidents)
                            : [];
                    });
                    allMappedServiceCalls = allMappedServiceCalls.concat(mappedServiceCalls);
                }

                // Wait for 3 seconds before processing the next chunk, unless it's the last chunk
                if (i < totalBatches - 1)
                    await delay(3000);
            }

            // Update rows with mapped data
            setRows(allMappedServiceCalls);
        } catch (error) {
            console.error("Error fetching service calls:", error);
        }
    }

    //fetch default service calls by chunked date range
    const fetchDefaultServiceCallsByChunk = async () => {
        setMappingLoad(true);
        const startDate = new Date();
        startDate.setDate(startDate.getDate() - (ManagerConstants.DEFAULT_NUM_OF_DAYS - 1)); //last n days, includes today
        startDate.setHours(0, 0, 0, 0);

        //generate endpoint chunks of size as per MAX_DAYS_PER_REQUEST
        const endpointsList = GenerateEndpointChunks(
            startDate,
            new Date().setHours(23, 59, 59, 999), //current date is end Date
            ManagerConstants.MAX_DAYS_PER_REQUEST,
            ApiEndpoints["API_GET_SERVICE_CALLS_BY_DATE_RANGE"]
        );
        await fetchEndpointsAsync(endpointsList);
        setMappingLoad(false);
    };

    //Load data first time
    useEffect(() => {
        fetchDefaultServiceCallsByChunk();
    }, [])

    useEffect(() => {
        const fetchServiceCallsByChunk = async () => {
            if (!selectedDateRange?.startDate || !selectedDateRange?.endDate) return;

            setMappingLoad(true);

            //generate endpoint chunks of size as per MAX_DAYS_PER_REQUEST
            const endpointsList = GenerateEndpointChunks(
                selectedDateRange.startDate,
                selectedDateRange.endDate,
                ManagerConstants.MAX_DAYS_PER_REQUEST,
                ApiEndpoints["API_GET_SERVICE_CALLS_BY_DATE_RANGE"]
            );
            await fetchEndpointsAsync(endpointsList);
            setMappingLoad(false);
        };

        fetchServiceCallsByChunk();
    }, [selectedDateRange]);

    // Memoizing the actions column
    const actionsCol = useMemo(() => ({
        accessorKey: "actions",
        header: "Actions",
        enableSorting: false,
        enableColumnActions: false,
        enableColumnFilter: false,
        Cell: ({ row }) => (
            <Box>
                <IconButton sx={{ p: 0 }}>
                    <IconsWithTooltip
                        title="Click to edit service call"
                        icon={<EditIcon
                            sx={{ color: themes.default.palette.primary.contrastText }}
                            onClick={() => { navigate('/manager/edit-service-call', { state: { serviceCallId: row.original.id } }) }}
                        />}
                    />
                </IconButton>
                <IconButton>
                    <IconsWithTooltip
                        title="Click to delete service call"
                        icon={<DeleteIcon sx={{ color: themes.default.palette.primary.contrastText }}
                            onClick={() => { setIsDialogOpen(true); setSelectedRow([row.original.id]); }}
                        />} />
                </IconButton>
            </Box>
        ),
        size: 80,
    }), []);

    useLayoutEffect(() => {
        if (primaryCols.length === 0) //Set default columns if primary cols are empty
            setPrimaryCols([actionsCol, ...ManagerConstants.SERVICE_CALL_DEFAULT_PRIMARY_COLUMNS]);
        else if (!primaryCols.some(col => col.accessorKey === 'actions'))
            setPrimaryCols(prevCols => [actionsCol, ...prevCols]);
    }, [primaryCols, actionsCol]);

    useLayoutEffect(() => {
        if (secondaryCols.length === 0) //Set all columns as default if secondary cols are empty
            setSecondaryCols([...ManagerConstants.SERVICE_CALL_ALL_COLUMNS]);
    }, [secondaryCols]);

    const handleDeleteConfirm = async () => {
        if (selectedRow?.length > 0) {
            await updateServiceCalls({
                id: selectedRow[0]
            })
            setSelectedRow([]);

            //reload data in table
            fetchDefaultServiceCallsByChunk();
        }
    }

    //Fetch data from API when reload data button is clicked
    const handleReloadServiceCallsData = async () => {
        await fetchDefaultServiceCallsByChunk();
    }

    return (
        <StyledCard>
            <StyledCardContent>
                <Grid container>

                    {/* View service call summaries link */}
                    <StyledGrid item xs={12} sm={5} md={5} lg={5}>
                        <StyledChip label="Service Call Summaries" onClick={() => navigate('/manager/service-call-summaries')} />
                    </StyledGrid>

                    {/* Page Title */}
                    <Grid item xs={12} sm={7} md={7} lg={7} sx={{ display: 'flex', alignItems: "center" }}>
                        <PageTitle aria-label="View All Service Calls" title="View All Service Calls" />
                    </Grid>

                    {mappingLoad ? (
                        <StyledGrid item xs={12}>
                            <Box sx={{ display: 'flex', justifyContent: 'center', height: '100%' }}>
                                <CircularProgress aria-label="loading indicator" />
                            </Box>
                        </StyledGrid>
                    ) : (
                        <StyledGrid item xs={12} sx={{ overflow: "hidden" }}>
                            <MaterialReactTable
                                columns={primaryCols}
                                data={rows}
                                enableToolbarInternalActions={false} //Disable default toolbar actions
                                renderTopToolbarCustomActions={({ table }) =>
                                    <CustomToolbarWithExport
                                        table={table}
                                        primaryCols={primaryCols}
                                        setPrimaryCols={setPrimaryCols}
                                        secondaryCols={secondaryCols}
                                        setSecondaryCols={setSecondaryCols}
                                        setSelectedDateRange={setSelectedDateRange}
                                        type="allServiceCalls"
                                        handleReloadData={handleReloadServiceCallsData}
                                    />}
                                initialState={{ showGlobalFilter: true }}
                                renderDetailPanel={rows?.length > 0 ? (row) => <DetailsPanel data={row} displayCols={secondaryCols} /> : null}
                            />
                        </StyledGrid>
                    )}
                </Grid>
            </StyledCardContent>

            {/* Delete Confirmation Dialog */}
            <ConfirmationDialog
                open={isDialogOpen}
                title="Delete Confirmation"
                content="Are you sure you want to delete selected service call?"
                onConfirm={handleDeleteConfirm}
                onClose={() => { setIsDialogOpen(false) }}
            />
        </StyledCard>
    )
}