export default function (passedStartDate, passedEndDate, chunkSize, endpoint) {
    const startDate = new Date(passedStartDate);
    const endDate = new Date(passedEndDate);

    //function to add days to a date
    const addDays = (date, days) => {
        const result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
    };

    let currentChunkStart = startDate;
    let apiUrlList = [];
    while (currentChunkStart < endDate) {

        //calculate currentChunkEnd
        let currentChunkEnd = addDays(currentChunkStart, chunkSize);

        //chunk end should not exceed end date
        if (currentChunkEnd > endDate)
            currentChunkEnd = endDate;

        //add to endpoint list
        apiUrlList.push(endpoint + "?from=" + currentChunkStart.toISOString() + "&to=" + currentChunkEnd.toISOString());

        //reset currentChunkStart for next chunk
        currentChunkStart = currentChunkEnd;
    }
    return apiUrlList;
}